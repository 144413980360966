import { TypeTese } from 'Functions/Tese/types/tese'
import { generateDb5 } from '../methods'
import { fnTese } from 'Functions/Tese'
import { fnInstitution } from 'Functions/Institution'
import { fnFollowup } from 'Functions/Followup'
import { modelTese } from 'Functions/Tese/model/modelTese'
import { modelTeseCredit } from 'Functions/TeseCredit/model/modelTeseCredit'
import { modelInstitution } from 'Functions/Institution/model/modelInstitution'
import { modelTeaser } from 'Functions/Teaser/model/modelTeaser'
import { modelMatch } from 'Functions/Match/model/modelMatch'
import { modelUser } from 'Functions/User/model/modelUser'
import { modelNotification } from 'Functions/Notification/methods/modelNotification'
import { modelFollowup } from 'Functions/Followup/model/modelFollowup'
import { modelDarwinFiles } from 'Functions/Darwin/model/modelDarwinFiles'
import { TypeTeseCredit } from 'Functions/TeseCredit/types/teseCredit'
import { TypeTeaser } from 'Functions/Teaser/types/teaser'
import { TypeInstitution } from 'Functions/Institution/types/institution'
import { TypeMatch } from 'Functions/Match/types/match'
import { TypeUser } from 'Functions/User/types/TypeUser'
import { TypeNotification } from 'Functions/Notification/types/notification'
import { TypeFollowup } from 'Functions/Followup/types/TypeFollowup'
import { TypeDarwinFiles } from 'Functions/Darwin/types/TypeDarwinFiles'
import { typeRanking } from 'Functions/RankingAgents/types/typeRanking'
import { modelRankingAgents } from 'Functions/RankingAgents'

import { TypeTransaction } from 'Screens/Sinatra/Types/TypeTransaction'
import { fnTransaction } from 'Screens/Sinatra/Functions/Transaction'
import { fnValuation } from 'Screens/Sinatra/Functions/Valuation'
import { TypeValuation } from 'Screens/Sinatra/Types/TypeValuation'
import { modelViewTeasers001 } from 'Functions/ViewTeaser001/modelViewTeasers001'
import { TypeViewTeasers001 } from 'Functions/ViewTeaser001/types/TypeViewTeasers001'
import { modelWalletLine } from 'Functions/WalletLine/model/modelWalletLine'
import { TypeWalletLine } from 'Functions/WalletLine/types/walletLine'
import { TypeTour } from 'Functions/Tour/Type/TypeTour'
import { modelTour } from 'Functions/Tour/Model/modelTour'
import { TypeCalendar } from 'Functions/Calendar/types/calendar'
import { modelCalendar } from 'Functions/Calendar/model/modelCalendar'
import { modelFeaturedMaterial } from 'Functions/FeaturedMaterials/model/modelFeaturedMaterial'
import { TypeFeaturedMaterial } from 'Functions/FeaturedMaterials/types/featureMaterial'
import { TypeTool } from 'Functions/Tools/types/tool'
import { modelTool } from 'Functions/Tools/model/modelTool'
import { modelProfilePost } from '../../../Functions/ProfilePosts/model/modelProfilePost'
import { TypeProfilePost } from '../../../Functions/ProfilePosts/types/ProfilePost'
import { modelValuations } from '../../../Functions/Valuation/modelValuations'
import { modelValuation } from '../../../Screens/Sinatra/Functions/Valuation/model'
import { Valuation } from '../../../Screens/Valuation/Types'
import { TypeTemplateEmailMatchmaking } from '../../../Screens/Followup/TemplateEmailMatchmaking/Types/TypeTemplateEmailMatchmaking'
import { TypeViewOriginacoes } from '../../../Functions/Originacoes/TypeViewOriginacoes'
import { modelViewOriginacoes } from '../../../Functions/Originacoes/modelViewOriginacoes'

// TIPAGEM DOS NOMES DAS COLEÇÕES USADAS
// QUANDO CRIAR UMA NOVA COLEÇÃO, ADICIONAR AQUI

export type TypeCollections = 'templateEmailMatchmakings' | 'valuationsOld' | 'valuations' | 'profilePosts' | 'tools' | 'featuredMaterials' | 'calendars' | 'walletLines' | 'teses' | 'tesesCredit' | 'teasers' | 'institutions' | 'matches' | 'users' | 'notifications' | 'followup' | 'darwinFiles' | 'sinatraTransactions' | 'sinatraValuations' | 'ranking' | '_viewTeasers001' | 'tour' | '_viewOriginacoes'

// CRIAÇÃO DO OBJETO QUE AGRUPA MÉTODOS PARA CADA COLEÇÃO
// QUANDO CRIAR UMA NOVA COLEÇÃO, ADICIONAR AQUI
// ADICIONE TAMBÉM A TIPAGEM E O MODELO
export const _db5 = {
	teses: generateDb5<TypeTese>('teses', modelTese),
	tools: generateDb5<TypeTool>('tools', modelTool),
	calendars: generateDb5<TypeCalendar>('calendars', modelCalendar),
	featuredMaterials: generateDb5<TypeFeaturedMaterial>('featuredMaterials', modelFeaturedMaterial),
	tesesCredit: generateDb5<TypeTeseCredit>('tesesCredit', modelTeseCredit),
	walletLines: generateDb5<TypeWalletLine>('walletLines', modelWalletLine),
	teasers: generateDb5<TypeTeaser>('teasers', modelTeaser),
	institutions: generateDb5<TypeInstitution>('institutions', modelInstitution),
	matches: generateDb5<TypeMatch>('matches', modelMatch),
	users: generateDb5<TypeUser>('users', modelUser),
	notifications: generateDb5<TypeNotification>('notifications', modelNotification),
	followup: generateDb5<TypeFollowup>('followup', modelFollowup),
	darwinFiles: generateDb5<TypeDarwinFiles>('darwinFiles', modelDarwinFiles),
	sinatraTransactions: generateDb5<TypeTransaction>('sinatraTransactions', fnTransaction.modelTransaction),
	sinatraValuations: generateDb5<TypeValuation>('sinatraValuations', fnValuation.modelValuation),
	ranking: generateDb5<typeRanking>('ranking', modelRankingAgents),
	_viewTeasers001: generateDb5<TypeViewTeasers001>('_viewTeasers001', modelViewTeasers001),
	_viewOriginacoes: generateDb5<TypeViewOriginacoes>('_viewOriginacoes', modelViewOriginacoes),
	viewTeasers001: generateDb5<TypeViewTeasers001>('_viewTeasers001', modelViewTeasers001),
	tour: generateDb5<TypeTour>('tour', modelTour),
	profilePosts: generateDb5<TypeProfilePost>('profilePosts', modelProfilePost),
	valuations: generateDb5<TypeValuation>('valuations', modelValuation),
	valuationsOld: generateDb5<Valuation>('valuationsOld', (valuation: Valuation) => ({ ...valuation })),
	templateEmailMatchmakings: generateDb5<TypeTemplateEmailMatchmaking>('templateEmailMatchmakings', (valuation: Valuation) => ({ ...valuation })),
}
