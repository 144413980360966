import { GUARANTEES, GUARANTEES_DETAILS } from '../../../../Constants/GUARANTEES'
import { GuaranteeProps, ItemGuranteeProps, TypeTeaser } from '../../types/teaser'
import { TypeTeseCredit } from '../../../TeseCredit/types/teseCredit'
import { coinToNumber } from '../../../Utils/moeda'
import { fnTeaser } from '../..'
import { clone } from '../../../Utils/clone'

/**
 * RULES
 * garantias marcadas na tese de crédito estão presentes no teaser?
 * das garantias desta intersecção, a soma dos valores é maior ou igual ao valor da operação?
 * pegar apenas se o investidor aceita ou não tal tipo de garantia
 * retornar um objeto com um valor booleano e um array com as garantias foram aceitas e seus valores
 * (Tese ) veiculos == outros (teaser)
*/

type AcceptedType = {
	guarantee: string
	total: number
}[]

const MINIMUM_VALUE_OF_A_OPERATION = 10_000_000

const checkHasGuarantee = (key: string, teseCredit: TypeTeseCredit): boolean => {
	const {
		garantiaImovel,
		garantiaVeiculos,
		garantiaBoletosDuplicatasContratos,
		garantiaRecebiveisImobiliarios,
		garantiaFiancaSafra
	} = teseCredit
	return (key === GUARANTEES.guarantee1 && garantiaImovel) ||
		(key === GUARANTEES.guarantee6 && garantiaVeiculos) ||
		(key === GUARANTEES.guarantee2 && garantiaBoletosDuplicatasContratos) ||
		(key === GUARANTEES.guarantee3 && garantiaRecebiveisImobiliarios) ||
		(key === GUARANTEES.guarantee4 && garantiaFiancaSafra)
}

type Props = {
	teaser: TypeTeaser
	teseCredit: TypeTeseCredit
}

type PropsReturn = {
	guarantees: AcceptedType
	approved: boolean
	message: string
}
/**
 *
 * @param key: string
 * @param teseCredit: TypeTeseCredit
 * @returns {guarantees, approved} as PropsReturn
 */
export const getGuaranteesCover = ({ teaser, teseCredit }: Props): PropsReturn => {

	const cloneTeaser = clone(teaser)
	const cloneTeseCredit = clone(teseCredit)

	const { guarantees, desiredValue, noGuarantees } = cloneTeaser
	// const allGuarantees =
	let message = 'A originação não possui garantias'
	if (noGuarantees) {
		return {
			approved: false,
			guarantees: [],
			message
		}
	}

	const acceptedGuarantees: AcceptedType = []// array com as garantias não aceitas
	let amountGuarantees = 0 // soma total do valor de todas as garantias
	let hasGuarantees = false // checa se exsite ao menos uma garantia para passar na validação

	if (guarantees && Object.keys(guarantees).length) {
		Object.keys(guarantees).forEach(key => {
			const guarantee = guarantees[key as GUARANTEES] || []
			//relaiza a soma de todas as garantias dessa garantia
			const sum = guarantee.reduce((accumulator, guarantee) => {
				let multiplicator = 1
				// retorna o multiplicador da garania
				if (guarantee.type && GUARANTEES_DETAILS[key].subType[guarantee.type])
					multiplicator = GUARANTEES_DETAILS[key].subType[guarantee.type].multiplicator(teaser)

				return accumulator + ((Number(coinToNumber(guarantee.value)) || 0) * multiplicator)
			}, 0)
			// checa se a tese de crédito possui a garantia
			if (checkHasGuarantee(key, cloneTeseCredit)) {
				hasGuarantees = true // indica que pelo menos uma garantia foi aceita
				amountGuarantees += sum // realiza a soma do total das garantias
				acceptedGuarantees.push({
					guarantee: key,
					total: sum
				})
			}
		})
	}
	const amountOperation = coinToNumber(desiredValue) || 0
	// const checkAmount = amountGuarantees >= amountOperation
	const checkAmount = amountGuarantees >= MINIMUM_VALUE_OF_A_OPERATION
	const approved = hasGuarantees && checkAmount

	if (!hasGuarantees) {
		message = 'Nenhuma garantia compatível'
	}
	else if (!checkAmount) {
		message = 'A soma das garantias é menor que o valor mínimo de uma operação'
	}
	else {
		message = 'Transação aprovada'
	}

	const data = {
		approved,
		guarantees: acceptedGuarantees,
		message,
		amountGuarantees
	}
	return data
}
