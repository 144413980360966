/**
 * A função _alert no TypeScript exibe um alerta com uma mensagem especificada.
 * @param - A função `_alert` é uma exportação nomeada que recebe um objeto como seu
 *parâmetro. O objeto deve ter uma propriedade `message` que é uma string. Quando
 * a função é chamada, ela exibirá um alerta com a mensagem fornecida.
 */
//CRIADO POR IA

export const _alert = ({ message }: { message: string }) => {
	alert(message)
}
