import { TypeTeaser } from '../../../../Functions/Teaser/types/teaser'
import { TCombinationFromAlgorithmTags } from '../../../../Screens/PreMatches/Functions/algorithmTags'
import { getTotalInsure } from '../get/getTotalInsure'
import { isMA } from '../isMA'
import { genFee } from './genCount/genFee'
import { genTransactionValue } from './genCount/genTransactionValue'
import { genValuation } from './genCount/genValuation'
import { scoreStarkCréditoCorporateFinance } from './scores/crédito/corporateFinance'
import { scoreStarkCréditoGreenField } from './scores/crédito/greenfield'
import { scoreStarkCréditoProjectFinance } from './scores/crédito/projectFinance'
import { scoreStarkMAMajoritário } from './scores/ma/majoritario'
import { scoreStarkMAMinoritário } from './scores/ma/minoritario'
import { scoreStarkMATotal } from './scores/ma/total'
import { TypeScoreProps } from './types/TypeScoreProps'
import { TypeScoreStark } from './types/TypeScoreStark'

export const scoreStark = (
	teaser: TypeTeaser,
	operation: TypeScoreProps['operation'],
	combinations: TCombinationFromAlgorithmTags
): TypeScoreStark => {

	let score: TypeScoreStark = {
		approved: false,
		scoreColor: 'red',
		scoreNote: 0,
		scoreItens: [],
		metadata: {
			valuation: 0,
			transactionValue: 0,
			successFee: 0,
			valueTotalInsure: 0,
			typeOperation: 'total',
			type: 'M&A',
			reperfilamento: false,
			numberOfInstitutions: {
				total: 0,
				specific: 0,
				setorial: 0,
				generalist: 0,
			},
			isAdjustableValue: false,
			insureCoverage: 0,
			governance: 'C',
			governanceColor: 'red',
			governanceCoverage: 0,
		}
	}

	const MINIMUM_VALUE_OF_A_OPERATION = 10_000_000

	const garantias = getTotalInsure(teaser)

	if (operation === 'total') {
		score = scoreStarkMATotal({
			teaser,
			operation,
			combinations,
		})
	}
	if (operation === 'minoritário') {
		score = scoreStarkMAMinoritário({
			teaser,
			operation,
			combinations,
		})
	}
	if (operation === 'majoritário') {
		score = scoreStarkMAMajoritário({
			teaser,
			operation,
			combinations,
		})
	}

	if (operation === 'greenfield') {
		score = scoreStarkCréditoGreenField({
			teaser,
			operation,
			combinations,
		})
	}
	if (operation === 'corporateFinance') {
		score = scoreStarkCréditoCorporateFinance({
			teaser,
			operation,
			combinations,
		})
	}
	if (operation === 'projectFinance') {
		score = scoreStarkCréditoProjectFinance({
			teaser,
			operation,
			combinations,
		})
	}

	const verifyIsAdjustableValue = () => {

		// Remove a possibilidade de aparecer nos casos de M&A
		if (
			operation === 'minoritário' ||
			operation === 'majoritário' ||
			operation === 'total'
		) return false

		if (score.approved) return false

		if (garantias > MINIMUM_VALUE_OF_A_OPERATION)
			return true

		return false
	}

	score.metadata = {
		...score.metadata,
		valuation: genValuation(teaser),
		transactionValue: genTransactionValue({ teaser, operation, combinations }),
		successFee: genFee({ teaser, operation, combinations }),
		valueTotalInsure: garantias,
		isAdjustableValue: verifyIsAdjustableValue()
	}

	return score
}
