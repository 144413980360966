import { isInRange } from '../../../../../Functions/Utils/isInRange'
import { getTotalInsure } from '../../get/getTotalInsure'
import { GEN_PERCENT_OF_COVERAGE_INSURE } from '../genConst/GEN_PERCENT_OF_COVERAGE_INSURE'
import { genTransactionValue } from '../genCount/genTransactionValue'
import { TypeScoreProps } from '../types/TypeScoreProps'
import { TypeScoreStark } from '../types/TypeScoreStark'

export const genScoreValueInsure = (props: TypeScoreProps) => {
	const res: TypeScoreStark['scoreItens'] = []

	const transactionValue = genTransactionValue(props)
	const valorTotalGarantias = getTotalInsure(props.teaser)

	const devolutiva = 'Cadastre TODAS as garantias possíveis.'

	if (props.operation === 'projectFinance') {
		if (isInRange(GEN_PERCENT_OF_COVERAGE_INSURE(props, 1) * transactionValue, '<=', valorTotalGarantias, '<', GEN_PERCENT_OF_COVERAGE_INSURE(props, 2) * transactionValue))
			res.push({ // adiciona 0.5
				'pontosMax': 2,
				'pontos': 0.5,
				'pontosCor': 'green',

				'setor': 'Garantias',

				'item': 'Cobertura de Garantias',
				'itemDescrição': `Suas garantias são de: ${valorTotalGarantias.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}, o mínimo para esta transação é de ${(GEN_PERCENT_OF_COVERAGE_INSURE(props, 1) * transactionValue).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`,

				'metaDados': '',
				'devolutiva': devolutiva,
			})

		else if (isInRange(GEN_PERCENT_OF_COVERAGE_INSURE(props, 2) * transactionValue, '<=', valorTotalGarantias, '<', GEN_PERCENT_OF_COVERAGE_INSURE(props, 3) * transactionValue))
			res.push({ // adiciona 1
				'pontosMax': 2,
				'pontos': 1,
				'pontosCor': 'green',

				'setor': 'Garantias',

				'item': 'Cobertura de Garantias',
				'itemDescrição': `Suas garantias são de: ${valorTotalGarantias.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}, o mínimo para esta transação é de ${(GEN_PERCENT_OF_COVERAGE_INSURE(props, 1) * transactionValue).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`,

				'metaDados': '',
				'devolutiva': devolutiva,
			})

		else if (isInRange(GEN_PERCENT_OF_COVERAGE_INSURE(props, 3) * transactionValue, '<=', valorTotalGarantias, '<', GEN_PERCENT_OF_COVERAGE_INSURE(props, 4) * transactionValue))
			res.push({ // adiciona 1.5
				'pontosMax': 2,
				'pontos': 1.5,
				'pontosCor': 'green',

				'setor': 'Garantias',

				'item': 'Cobertura de Garantias',
				'itemDescrição': `Suas garantias são de: ${valorTotalGarantias.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}, o mínimo para esta transação é de ${(GEN_PERCENT_OF_COVERAGE_INSURE(props, 1) * transactionValue).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`,

				'metaDados': '',
				'devolutiva': devolutiva,
			})

		else if (GEN_PERCENT_OF_COVERAGE_INSURE(props, 4) * transactionValue <= valorTotalGarantias)
			res.push({ // adiciona 2
				'pontosMax': 2,
				'pontos': 2,
				'pontosCor': 'green',

				'setor': 'Garantias',

				'item': 'Cobertura de Garantias',
				'itemDescrição': `Suas garantias são de: ${valorTotalGarantias.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}, o mínimo para esta transação é de ${(GEN_PERCENT_OF_COVERAGE_INSURE(props, 1) * transactionValue).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`,

				'metaDados': '',
				'devolutiva': '',
			})

		else {
			res.push({ // adiciona 0
				'pontosMax': 2,
				'pontos': 0,
				'pontosCor': 'green',

				'setor': 'Garantias',

				'item': 'Cobertura de Garantias',
				'itemDescrição': 'Cliente não possui garantias suficientes',

				'metaDados': '',
				'devolutiva': devolutiva,
			})
		}
	}

	if (props.operation === 'corporateFinance') {

		if (transactionValue < valorTotalGarantias) {
			if (isInRange(GEN_PERCENT_OF_COVERAGE_INSURE(props, 1) * transactionValue, '<=', valorTotalGarantias, '<', GEN_PERCENT_OF_COVERAGE_INSURE(props, 2) * transactionValue)) {
				res.push({ // adiciona 1
					'pontosMax': 3,
					'pontos': 1,
					'pontosCor': 'green',

					'setor': 'Garantias',

					'item': 'Cobertura de Garantias',
					'itemDescrição': `Suas garantias são de: ${valorTotalGarantias.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}, o mínimo para esta transação é de ${(GEN_PERCENT_OF_COVERAGE_INSURE(props, 1) * transactionValue).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`,

					'metaDados': '',
					'devolutiva': devolutiva,
				})
			}
			else if (isInRange(GEN_PERCENT_OF_COVERAGE_INSURE(props, 2) * transactionValue, '<=', valorTotalGarantias, '<', GEN_PERCENT_OF_COVERAGE_INSURE(props, 3) * transactionValue)) {
				res.push({ // adiciona 2
					'pontosMax': 3,
					'pontos': 2,
					'pontosCor': 'green',

					'setor': 'Garantias',

					'item': 'Cobertura de Garantias',
					'itemDescrição': `xxx Suas garantias são de: ${valorTotalGarantias.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}, o mínimo para esta transação é de ${(GEN_PERCENT_OF_COVERAGE_INSURE(props, 2) * transactionValue).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`,

					'metaDados': '',
					'devolutiva': devolutiva,
				})
			}
			else if (GEN_PERCENT_OF_COVERAGE_INSURE(props, 3) * transactionValue <= valorTotalGarantias) {
				res.push({ // adiciona 3
					'pontosMax': 3,
					'pontos': 3,
					'pontosCor': 'green',

					'setor': 'Garantias',

					'item': 'Cobertura de Garantias',
					'itemDescrição': `Suas garantias são de: ${valorTotalGarantias.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}, o mínimo para esta transação é de ${(GEN_PERCENT_OF_COVERAGE_INSURE(props, 3) * transactionValue).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`,

					'metaDados': '',
					'devolutiva': '',
				})
			}
			else {
				res.push({ // adiciona 0
					'pontosMax': 3,
					'pontos': 0,
					'pontosCor': 'green',

					'setor': 'Garantias',

					'item': 'Cobertura de Garantias',
					'itemDescrição': `Suas garantias são de: ${valorTotalGarantias.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}, não são suficientes para esta transação`,

					'metaDados': '',
					'devolutiva': devolutiva,
				})
			}
		}

		else if (10_000_000 < valorTotalGarantias) {
			res.push({ // adiciona 1
				'pontosMax': 1,
				'pontos': 1,
				'pontosCor': 'green',

				'setor': 'Garantias',

				'item': 'Cobertura de Garantias',
				'itemDescrição': `REPESCAGEM: Suas garantias são de ${valorTotalGarantias.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} e está acima de R$ 20.000.000`,

				'metaDados': '',
				'devolutiva': '',
			})
			res.push({ // adiciona 0
				'pontosMax': 3,
				'pontos': 0,
				'pontosCor': 'green',

				'setor': 'Garantias',

				'item': 'Cobertura de Garantias',
				'itemDescrição': `Suas garantias são de: ${valorTotalGarantias.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}, o mínimo para esta transação é de ${(GEN_PERCENT_OF_COVERAGE_INSURE(props, 3) * transactionValue).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`,

				'metaDados': '',
				'devolutiva': devolutiva,
			})
		}

		else {
			res.push({ // adiciona 0
				'pontosMax': 1,
				'pontos': 0,
				'pontosCor': 'red',

				'setor': 'Garantias',

				'item': 'Cobertura de Garantias',
				'itemDescrição': 'Cliente não possui garantias suficientes',

				'metaDados': '',
				'devolutiva': '',
			})
			res.push({ // adiciona 0
				'pontosMax': 3,
				'pontos': 0,
				'pontosCor': 'green',

				'setor': 'Garantias',

				'item': 'Cobertura de Garantias',
				'itemDescrição': `Suas garantias de: ${valorTotalGarantias.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}, não são suficientes para esta transação`,

				'metaDados': '',
				'devolutiva': devolutiva,
			})
		}
	}

	return res
}
