import { TypeTeaser } from '../../types/teaser'
import { TypeUserGenerics } from '../../../User/types/userGenerics'
import { getUidAuth } from '../getUidAuth'
import { TYPE_USER_LABEL } from '../../../../Constants/TYPE_USER'
// import { fnUser } from '../../../User'
import { clone } from '../../../Utils/clone'
import { modelUser } from '../../../User/model/modelUser'
import { getLabelTypeUser } from '../../../User/methods/getLabelTypeUser'
import { isOffice } from '../../../User/methods/isOffice'
import { isEnterprise } from '../../../User/methods/isEnterprise'
import { getUidCoAuth } from '../../../User/methods/getUidCoAuth'

const labelAgent = {
	[TYPE_USER_LABEL.adm]: {
		color: 'yellow-400',
		label: [TYPE_USER_LABEL.adm],
	},
	[TYPE_USER_LABEL.bda]: {
		color: 'blue-500',
		label: [TYPE_USER_LABEL.bda],
	},
	[TYPE_USER_LABEL.customer]: {
		color: 'blue-500',
		label: [TYPE_USER_LABEL.customer],
	},
	[TYPE_USER_LABEL.enterprise]: {
		color: 'green-400',
		label: [TYPE_USER_LABEL.enterprise],
	},
	[TYPE_USER_LABEL.microBda]: {
		color: 'green-400',
		label: [TYPE_USER_LABEL.microBda],
	},
	[TYPE_USER_LABEL.hub]: {
		color: 'orange-400',
		label: [TYPE_USER_LABEL.hub],
	},
	[TYPE_USER_LABEL.office]: {
		color: 'orange-400',
		label: [TYPE_USER_LABEL.office],
	},
	[TYPE_USER_LABEL.officeAnalyst]: {
		color: 'orange-400',
		label: [TYPE_USER_LABEL.officeAnalyst],
	},
}

export const getAuthor = ({ teaser, users }: { teaser: TypeTeaser, users: TypeUserGenerics }) => {

	const cloneTeaser = clone(teaser)
	const cloneUsers = clone(users)

	const author = cloneUsers?.[getUidAuth(cloneTeaser)] || modelUser({ tipo: 'BDA' })

	const agent = labelAgent[getLabelTypeUser(author) || TYPE_USER_LABEL.bda]
	const haveSecondName = !isOffice(author) && !isEnterprise(author)
	const type = agent.label
	const complement = haveSecondName ? cloneUsers[getUidCoAuth(author)]?.nome || 'Stark' : ''
	const bulletColor = agent.color

	return {
		author,
		type,
		complement,
		bulletColor
	}
}
