// @ts-nocheck
import { lazy, Suspense } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'

import Login from '../Screens/Login'
import Screen_Recover from '../Screens/Recover'
import CreateBda from 'Screens/CreateBda'

import { LoadingProgressText } from 'Components/LoadingProgress/LoadingProgressText'
import { LayoutContainer } from 'Components/Layout/LayoutContainer'
import { Lgpd } from 'Components/Lgpd'
import { fnUser } from 'Functions/User'
import { useContextAuth } from './ContextAuth'
import useScrollToTop from 'Hooks/useScrollToTop'
import useAnalytics from 'Hooks/useAnalytics'

const MudarEmail = lazy(() => import('Screens/MudarEmail'))
const RecebeMatch = lazy(() => import('Screens/RecebeMatch'))
const LinkNda = lazy(() => import('Screens/LinkNda'))
const Estudo = lazy(() => import('Screens/Estudo'))
const KitComercial = lazy(() => import('Screens/KitComercial'))
const CustomerKitComercial = lazy(() => import('Screens/CustomerKitComercial'))
const DashEnterprise = lazy(() => import('Screens/DashEnterprise'))
const CustomerProfile = lazy(() => import('Screens/CustomerProfile'))
const TeaserOff = lazy(() => import('../Screens/Teaser/TeaserOff'))
const Institutions = lazy(() => import('../Screens/Institutions'))
const DashAgentIndex = lazy(() => import('Screens/DashAgent'))
const Index_PerfilBDA = lazy(() => import('Screens/ProfileAgent'))
const DataBase = lazy(() => import('Screens/DataBase/DataBase2'))
const DashBoardCustomer = lazy(() => import('Screens/DashBoardCustomer'))
// const PreMatches = lazy(() => import('Screens/PreMatches'))
const ActiveCustomers = lazy(() => import('Screens/ActiveCustomers'))
const TeseCredit = lazy(() => import('Screens/TeseCredit'))
const UserControl = lazy(() => import('Screens/UserControl'))
const DashBoardAgent = lazy(() => import('Screens/DashboardAgent'))
const Originacoes = lazy(() => import('Screens/Originacoes'))
const NewTeses = lazy(() => import('Screens/NewTeses'))
const Valuation = lazy(() => import('Screens/Valuation'))
const Teaser = lazy(() => import('Screens/Teaser'))
const DashOffice = lazy(() => import('Screens/DashOffice'))
const AddOriginacaoObrigado = lazy(() => import('Screens/AddOriginacaoObrigado'))
const UserManagement = lazy(() => import('Screens/UserManagement'))
const Users = lazy(() => import('Screens/Users'))
const AgentAdd = lazy(() => import('Screens/AgentAdd'))
const ProfileOffice = lazy(() => import('Screens/Perfil/Office'))
const ComercialPipe = lazy(() => import('Screens/ComercialPipe'))
const ProfileCompany = lazy(() => import('Screens/ProfileCompany'))
const AddOriginacao = lazy(() => import('Screens/AddOriginacao'))
const DarwinLight = lazy(() => import('Screens/DarwinLight'))
const DarwinTwo = lazy(() => import('Screens/DarwinTwo'))
const TeseOff = lazy(() => import('Screens/Tese/TeseOff'))
const Index_Tese = lazy(() => import('Screens/Tese'))
const Originations = lazy(() => import('Screens/Originations/Originations'))
const TesesCredit = lazy(() => import('Screens/TesesCredit'))
const Academy = lazy(() => import('Screens/Academy'))
const DashBoard = lazy(() => import('Screens/DashBoard'))
const ModeloPadrão = lazy(() => import('Screens/ModeloPadrão'))
const Sinatra = lazy(() => import('Screens/Sinatra'))
const AddValuation = lazy(() => import('Screens/Sinatra/Pages/AddValuation'))
const Transactions = lazy(() => import('Screens/Sinatra/Pages/Transactions'))
const AlexandriaScreen = lazy(() => import('Screens/Alexandria/'))
const TeseCreditNew = lazy(() => import('Screens/TeseCreditNew/TeseCreditNew'))
const TeseCreditEdit = lazy(() => import('Screens/TeseCreditEdit/TeseCreditEdit'))
const ValuationDetail = lazy(() => import('Screens/Sinatra/Pages/ValuationDetail'))
const Valuations = lazy(() => import('Screens/Sinatra/Pages/Valuations'))
const Screen_Institution = lazy(() => import('Screens/Institution'))
const Screen_TeseAdd = lazy(() => import('Screens/TeseAdd'))
const Originators = lazy(() => import('Screens/Originators'))
const OriginatorDashboard = lazy(() => import('Screens/Originator/Pages/OriginatorDashboard'))
const OriginatorTools = lazy(() => import('Screens/Originator/Pages/OriginatorTools'))
const OriginatorGeneral = lazy(() => import('Screens/Originator/Pages/OriginatorGeneral'))
const OriginatorOriginations = lazy(() => import('Screens/Originator/Pages/OriginatorOriginations'))
const OriginatorCommunication = lazy(() => import('Screens/Originator/Pages/OriginatorCommunication'))
const OriginatorFinancial = lazy(() => import('Screens/Originator/Pages/OriginatorFInancial'))
const Insightz = lazy(() => import('Screens/Insightz/Insightz'))
const SiteMap = lazy(() => import('Screens/SiteMap/SiteMap'))
const PreMatchList = lazy(() => import('Screens/PreMatchList'))
const PreMatch = lazy(() => import('Screens/PreMatch'))
const DashBoardSnaps = lazy(() => import('Screens/DashBoardSnaps'))
const DashBoardSnaps2 = lazy(() => import('Screens/DashBoardSnaps/DashBoardSnaps2'))
const ActiveCustomerDashboard = lazy(() => import('Screens/ActiveCustomerDashboard'))
const ActiveCustomerCommunication = lazy(() => import('Screens/ActiveCustomerCommunication'))
const FollowupOptout = lazy(() => import('Screens/Followup/Optout'))
const PreOriginacao = lazy(() => import('Screens/PreOriginacao'))
const ProfileCorporativo = lazy(() => import('Screens/Perfil/Corporativo'))
const ContratosNda = lazy(() => import('Screens/ContratoNda/ContratosNda'))
const ContratosPrivacidade = lazy(() => import('Screens/ContratoPrivacidade/ContratoPrivacidade'))
const Page404 = lazy(() => import('Screens/Page404/Page404'))
const TeseCreditMigrate = lazy(() => import('Screens/TeseCreditMigrate/TeseCreditMigrate'))
const AddOriginacaoNew = lazy(() => import('../Screens/AddOriginacaoNew'))
const ProfileCompany2 = lazy(() => import('../Screens/ProfileCompany2/ProfileCompany2'))
const SandBox = lazy(() => import('../Screens/SandBox/SandBox'))

export default () => {
	const { globalAuth, } = useContextAuth()

	const profile = globalAuth.profile
	const location = useLocation()
	const desconectado = globalAuth.auth === 'offLine' /* se estiver desconectado */
	const conectado = globalAuth.auth === 'onLine' /* se estiver desconectado */
	const carregando = globalAuth.uid === '' || globalAuth.auth === '' /* se estiver desconectado */

	const adm = fnUser.isAdm(profile)
	const bda = fnUser.isBda(profile)
	const customer = fnUser.isCustomer(profile)
	const enterprise = fnUser.isEnterprise(profile)
	const microBda = fnUser.isMicroBda(profile)
	const office = fnUser.isOffice(profile)
	const officeAnalyst = fnUser.isOfficeAnalyst(profile)
	useScrollToTop()
	useAnalytics()
	Lgpd()

	const ThisLoading = () => <LoadingProgressText />

	return (
		<AnimatePresence exitBeforeEnter>
			<Switch location={location} key={location.pathname}>

				<Route exact path='/followup/optout/' component={FollowupOptout} />
				<Route exact path='/contrato-confidencialidade' component={() => <Suspense fallback={<ThisLoading />}><ContratosNda /></Suspense>} />
				<Route exact path='/contrato-privacidade' component={() => <Suspense fallback={<ThisLoading />}><ContratosPrivacidade /></Suspense>} />

				{desconectado && <Route exact path='/recover' component={Screen_Recover} />}
				{desconectado && <Route exact path='/teaser/:id' component={() => <LayoutContainer menu={false}><Suspense fallback={<ThisLoading />}><TeaserOff /> </Suspense></LayoutContainer>} />}
				{desconectado && <Route exact path='/tese/:id' component={() => <LayoutContainer menu={false}><Suspense fallback={<ThisLoading />}><TeseOff /></Suspense></LayoutContainer>} />}
				{desconectado && <Route exact path='/add-originacao/:uid?/:uidCo?/:isFromOffice?' component={() => <LayoutContainer menu={false}><Suspense fallback={<ThisLoading />}><AddOriginacaoNew /></Suspense></LayoutContainer>} />}
				{desconectado && <Route exact path='/teaser-add-obrigado/' component={() => <LayoutContainer menu={false}><Suspense fallback={<ThisLoading />}><AddOriginacaoObrigado /></Suspense></LayoutContainer>} />}
				{desconectado && <Route exact path='/recebe-match/:id' component={RecebeMatch} />}
				{desconectado && <Route exact path='/pre-originacao/:uid?/:uidCo?/:isFromOffice?' component={() => <Suspense fallback={<ThisLoading />}><PreOriginacao /></Suspense>} />}
				{desconectado && <Route component={Login} />}
				{carregando && <Route component={() => <LoadingProgressText />} />}

				{adm && <Route exact path='/mudar-email' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><MudarEmail /></Suspense></LayoutContainer>} />}
				{adm && <Route exact path='/site-map' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><SiteMap /></Suspense></LayoutContainer>} />}
				{adm && <Route exact path='/teaser/:id' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><Teaser /></Suspense></LayoutContainer>} />}
				{adm && <Route exact path='/teses/' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><NewTeses /></Suspense></LayoutContainer>} />}
				{adm && <Route exact path='/add-originacao/:id?' component={() => <LayoutContainer menu={false}><Suspense fallback={<ThisLoading />}><AddOriginacaoNew /></Suspense></LayoutContainer>} />}
				{adm && <Route exact path='/add-originacao-2/:id?' component={() => <LayoutContainer menu={false}><Suspense fallback={<ThisLoading />}><AddOriginacao /></Suspense></LayoutContainer>} />}
				{/* {adm && <Route exact path='/longList' component={() => <LayoutContainer><LongList /></LayoutContainer>} />} */}
				{adm && <Route exact path='/active-customers' component={() => <LayoutContainer customLayout><Suspense fallback={<ThisLoading />}><ActiveCustomers /></Suspense></LayoutContainer>} />}
				{adm && <Route exact path='/active-customer/:id/dashboard' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><ActiveCustomerDashboard /></Suspense></LayoutContainer>} />}
				{adm && <Route exact path='/active-customer/:teaserId/communication/:matchId' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><ActiveCustomerCommunication /></Suspense></LayoutContainer>} />}
				{adm && <Route exact path='/database' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><DataBase /></Suspense></LayoutContainer>} />}
				{adm && <Route exact path='/darwin-light' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><DarwinLight /></Suspense></LayoutContainer>} />}
				{adm && <Route exact path='/darwin-two' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><DarwinTwo /></Suspense></LayoutContainer>} />}
				{adm && <Route exact path='/teasers' component={() => <LayoutContainer customLayout><Suspense fallback={<ThisLoading />}><Originacoes /></Suspense></LayoutContainer>} />}
				{adm && <Route exact path='/tese/:id' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><Index_Tese /></Suspense></LayoutContainer>} />}
				{adm && <Route exact path='/instituicoes' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><Institutions /></Suspense></LayoutContainer>} />}
				{adm && <Route exact path='/instituicao/:id?' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><Screen_Institution /></Suspense></LayoutContainer>} />}
				{adm && <Route exact path='/teseadd/:id?/:idInstitution?' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><Screen_TeseAdd /></Suspense></LayoutContainer>} />}
				{adm && <Route exact path='/matches/pre-matches' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><PreMatchList /></Suspense></LayoutContainer>} />}
				{adm && <Route exact path='/matches/pre-matches/:teaserId/:step' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><PreMatch /></Suspense></LayoutContainer>} />}
				{adm && <Route exact path='/sinatra' component={() => <LayoutContainer bgWhite><Suspense fallback={<ThisLoading />}><Sinatra /></Suspense></LayoutContainer>} />}
				{adm && <Route exact path='/sinatra/valuation/:idValuation' component={() => <LayoutContainer bgWhite><Suspense fallback={<ThisLoading />}><ValuationDetail /></Suspense></LayoutContainer>} />}
				{adm && <Route exact path='/sinatra/valuations/' component={() => <LayoutContainer bgWhite><Suspense fallback={<ThisLoading />}><Valuations /></Suspense></LayoutContainer>} />}
				{adm && <Route exact path='/sinatra/add-valuation' component={() => <LayoutContainer bgWhite customLayout><Suspense fallback={<ThisLoading />}><AddValuation /></Suspense></LayoutContainer>} />}
				{adm && <Route exact path='/sinatra/add-valuation/:idTeaser' component={() => <LayoutContainer bgWhite customLayout><Suspense fallback={<ThisLoading />}><AddValuation /></Suspense></LayoutContainer>} />}
				{adm && <Route exact path='/sinatra/transactions/:idTransaction?' component={() => <LayoutContainer bgWhite><Suspense fallback={<ThisLoading />}><Transactions /></Suspense></LayoutContainer>} />}
				{adm && <Route exact path='/valuation/:id' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><Valuation /></Suspense></LayoutContainer>} />}
				{adm && <Route exact path='/linkNda/:id' component={LinkNda} />}
				{adm && <Route exact path='/create-bda/' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><CreateBda /></Suspense></LayoutContainer>} />}
				{adm && <Route exact path='/kit-comercial' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><KitComercial /></Suspense></LayoutContainer>} />}
				{adm && <Route exact path='/tese-credit/:id' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><TeseCredit /></Suspense></LayoutContainer>} />}
				{adm && <Route exact path='/user-control/' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><UserControl /></Suspense></LayoutContainer>} />}
				{adm && <Route exact path='/user-management/' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><UserManagement /></Suspense></LayoutContainer>} />}
				{adm && <Route exact path='/originators/' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><Originators /></Suspense></LayoutContainer>} />}
				{adm && <Route exact path='/originator/:uid/dashboard' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><OriginatorDashboard /></Suspense></LayoutContainer>} />}
				{adm && <Route exact path='/originator/:uid/profile' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><OriginatorGeneral /></Suspense></LayoutContainer>} />}
				{adm && <Route exact path='/originator/:uid/originations' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><OriginatorOriginations /></Suspense></LayoutContainer>} />}
				{adm && <Route exact path='/originator/:uid/communication' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><OriginatorCommunication /></Suspense></LayoutContainer>} />}
				{adm && <Route exact path='/originator/:uid/financial' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><OriginatorFinancial /></Suspense></LayoutContainer>} />}
				{adm && <Route exact path='/users/' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><Users /></Suspense></LayoutContainer>} />}
				{adm && <Route exact path='/agent-add' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><AgentAdd /></Suspense></LayoutContainer>} />}
				{adm && <Route exact path='/comercial-pipe/' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><ComercialPipe /></Suspense></LayoutContainer>} />}
				{adm && <Route exact path='/profile-company/:id' component={() => <LayoutContainer customLayout><Suspense fallback={<ThisLoading />}><ProfileCompany /></Suspense></LayoutContainer>} />}
				{adm && <Route exact path='/teaser-add-obrigado/:id?' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><AddOriginacaoObrigado /></Suspense></LayoutContainer>} />}
				{adm && <Route exact path='/originations/' component={() => <LayoutContainer customLayout><Suspense fallback={<ThisLoading />}><Originations /></Suspense></LayoutContainer>} />}
				{adm && <Route exact path='/teses-credit/' component={() => <LayoutContainer customLayout><Suspense fallback={<ThisLoading />}><TesesCredit /></Suspense></LayoutContainer>} />}
				{adm && <Route exact path='/dashboard/' component={() => <LayoutContainer ><Suspense fallback={<ThisLoading />}><DashBoard /></Suspense></LayoutContainer>} />}
				{adm && <Route exact path='/perfil/' component={() => <LayoutContainer customLayout><Suspense fallback={<ThisLoading />}><ProfileCorporativo /></Suspense></LayoutContainer>} />}
				{adm && <Route exact path='/alexandria/:tab?' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><AlexandriaScreen /></Suspense></LayoutContainer>} />}
				{adm && <Route exact path='/add-tese-credit/:id' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><TeseCreditNew /></Suspense></LayoutContainer>} />}
				{adm && <Route exact path='/edit-tese-credit/:id' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><TeseCreditEdit /></Suspense></LayoutContainer>} />}
				{adm && <Route exact path='/migrate-tese-credit/:id/:edit?' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><TeseCreditMigrate /></Suspense></LayoutContainer>} />}
				{adm && <Route exact path='/painel' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><DashBoard /></Suspense></LayoutContainer>} />}
				{adm && <Route exact path='/insightz' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><Insightz /></Suspense></LayoutContainer>} />}
				{adm && <Route exact path='/canais/dashboard' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><OriginatorDashboard /></Suspense></LayoutContainer>} />}
				{adm && <Route exact path='/canais/tools' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><OriginatorTools /></Suspense></LayoutContainer>} />}
				{adm && <Route exact path='/canais/profile' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><OriginatorGeneral /></Suspense></LayoutContainer>} />}
				{adm && <Route exact path='/canais/originations' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><OriginatorOriginations /></Suspense></LayoutContainer>} />}
				{adm && <Route exact path='/canais/communication' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><OriginatorCommunication /></Suspense></LayoutContainer>} />}
				{adm && <Route exact path='/canais/financial' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><OriginatorFinancial /></Suspense></LayoutContainer>} />}
				{adm && <Route exact path='/snaps' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><DashBoardSnaps /></Suspense></LayoutContainer>} />}
				{adm && <Route exact path='/snaps2' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><DashBoardSnaps2 /></Suspense></LayoutContainer>} />}
				{adm && <Route exact path='/profile-company2/:teaserId?' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><ProfileCompany2 /></Suspense></LayoutContainer>} />}
				{adm && <Route exact path='/sand-box/:teaserId?' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><SandBox /></Suspense></LayoutContainer>} />}
				{adm && <Route exact path='/' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><DashBoard /></Suspense></LayoutContainer>} />}

				{bda && <Route exact path='/mudar-email' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><MudarEmail /></Suspense></LayoutContainer>} />}
				{bda && <Route exact path='/site-map' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><SiteMap /></Suspense></LayoutContainer>} />}
				{bda && <Route exact path='/add-originacao/:id?' component={() => <LayoutContainer menu={false}><Suspense fallback={<ThisLoading />}><AddOriginacaoNew /></Suspense></LayoutContainer>} />}
				{bda && <Route exact path='/teses/' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><NewTeses /></Suspense></LayoutContainer>} />}
				{bda && <Route exact path='/teaser/:id' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><Teaser /></Suspense></LayoutContainer>} />}
				{bda && <Route exact path='/kit-comercial' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><KitComercial /></Suspense></LayoutContainer>} />}
				{bda && <Route exact path='/tese/:id' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><Index_Tese /></Suspense></LayoutContainer>} />}
				{bda && <Route exact path='/teasers' component={() => <LayoutContainer customLayout><Suspense fallback={<ThisLoading />}><Originacoes /></Suspense></LayoutContainer>} />}
				{bda && <Route exact path='/teasers/:newOrigination' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><Originacoes /></Suspense></LayoutContainer>} />}
				{bda && <Route exact path='/perfil' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><Index_PerfilBDA /></Suspense></LayoutContainer>} />}
				{bda && <Route exact path='/teaser-add-obrigado/:id?' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><AddOriginacaoObrigado /></Suspense></LayoutContainer>} />}
				{bda && <Route exact path='/' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><DashAgentIndex /></Suspense></LayoutContainer>} />}
				{bda && <Route exact path='/pre-originacao/:uid?/:uidCo?/:isFromOffice?' component={() => <Suspense fallback={<ThisLoading />}><PreOriginacao /></Suspense>} />}
				{bda && <Route exact path='/painel' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><DashAgentIndex /></Suspense></LayoutContainer>} />}

				{enterprise && <Route exact path='/mudar-email' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><MudarEmail /></Suspense></LayoutContainer>} />}
				{enterprise && <Route exact path='/site-map' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><SiteMap /></Suspense></LayoutContainer>} />}
				{enterprise && <Route exact path='/add-originacao/:id?' component={() => <LayoutContainer menu={false}><Suspense fallback={<ThisLoading />}><AddOriginacaoNew /></Suspense></LayoutContainer>} />}
				{enterprise && <Route exact path='/teses/' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><NewTeses /></Suspense></LayoutContainer>} />}
				{enterprise && <Route exact path='/teaser/:id' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><Teaser /></Suspense></LayoutContainer>} />}
				{enterprise && <Route exact path='/kit-comercial' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><KitComercial /></Suspense></LayoutContainer>} />}
				{enterprise && <Route exact path='/tese/:id' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><Index_Tese /></Suspense></LayoutContainer>} />}
				{enterprise && <Route exact path='/teasers' component={() => <LayoutContainer customLayout><Suspense fallback={<ThisLoading />}><Originacoes /></Suspense></LayoutContainer>} />}
				{enterprise && <Route exact path='/teasers/:newOrigination' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><Originacoes /></Suspense></LayoutContainer>} />}
				{enterprise && <Route exact path='/perfil' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><Index_PerfilBDA /></Suspense></LayoutContainer>} />}
				{enterprise && <Route exact path='/teaser-add-obrigado/' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><AddOriginacaoObrigado /></Suspense></LayoutContainer>} />}
				{enterprise && <Route exact path='/' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><DashEnterprise /></Suspense></LayoutContainer>} />}
				{enterprise && <Route exact path='/painel' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><DashEnterprise /></Suspense></LayoutContainer>} />}

				{office && <Route exact path='/mudar-email' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><MudarEmail /></Suspense></LayoutContainer>} />}
				{office && <Route exact path='/site-map' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><SiteMap /></Suspense></LayoutContainer>} />}
				{office && <Route exact path='/active-customers' component={() => <LayoutContainer customLayout><Suspense fallback={<ThisLoading />}><ActiveCustomers /></Suspense></LayoutContainer>} />}
				{office && <Route exact path='/active-customer/:id/dashboard' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><ActiveCustomerDashboard /></Suspense></LayoutContainer>} />}
				{office && <Route exact path='/active-customer/:teaserId/communication/:matchId' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><ActiveCustomerCommunication /></Suspense></LayoutContainer>} />}
				{office && <Route exact path='/add-originacao/:id?' component={() => <LayoutContainer menu={false}><Suspense fallback={<ThisLoading />}><AddOriginacaoNew /></Suspense></LayoutContainer>} />}
				{office && <Route exact path='/teses/' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><NewTeses /></Suspense></LayoutContainer>} />}
				{office && <Route exact path='/teaser/:id' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><Teaser /></Suspense></LayoutContainer>} />}
				{office && <Route exact path='/kit-comercial' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><KitComercial /></Suspense></LayoutContainer>} />}
				{office && <Route exact path='/tese/:id' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><Index_Tese /></Suspense></LayoutContainer>} />}
				{office && <Route exact path='/teasers' component={() => <LayoutContainer customLayout><Suspense fallback={<ThisLoading />}><Originacoes /></Suspense></LayoutContainer>} />}
				{office && <Route exact path='/teasers/:newOrigination' component={() => <LayoutContainer customLayout><Suspense fallback={<ThisLoading />}><Originacoes /></Suspense></LayoutContainer>} />}
				{office && <Route exact path='/teaser-add-obrigado/:id?' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><AddOriginacaoObrigado /></Suspense></LayoutContainer>} />}
				{office && <Route exact path='/perfil/' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><ProfileOffice /></Suspense></LayoutContainer>} />}
				{office && <Route exact path='/darwin-light' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><DarwinLight /></Suspense></LayoutContainer>} />}
				{office && <Route exact path='/originations' component={() => <LayoutContainer customLayout><Suspense fallback={<ThisLoading />}><Originations /></Suspense></LayoutContainer>} />}
				{office && <Route exact path='/profile-company/:id' component={() => <LayoutContainer customLayout><Suspense fallback={<ThisLoading />}><ProfileCompany /></Suspense></LayoutContainer>} />}
				{office && <Route exact path='/user-management/' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><UserManagement /></Suspense></LayoutContainer>} />}
				{office && <Route exact path='/' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><DashOffice /></Suspense></LayoutContainer>} />}
				{office && <Route exact path='/painel' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><DashOffice /></Suspense></LayoutContainer>} />}

				{customer && <Route exact path='/mudar-email' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><MudarEmail /></Suspense></LayoutContainer>} />}
				{customer && <Route exact path='/site-map' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><SiteMap /></Suspense></LayoutContainer>} />}
				{customer && <Route exact path='/teaser/:id' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><Teaser /></Suspense></LayoutContainer>} />}
				{customer && <Route exact path='/kit-stark' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><CustomerKitComercial /></Suspense></LayoutContainer>} />}
				{customer && <Route exact path='/perfil' component={() => <LayoutContainer customLayout><Suspense fallback={<ThisLoading />}><CustomerProfile /></Suspense></LayoutContainer>} />}
				{customer && <Route exact path='/tese/:id' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><Index_Tese /></Suspense></LayoutContainer>} />}
				{customer && <Route exact path='/valuation/:id' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><Valuation /></Suspense></LayoutContainer>} />}
				{customer && <Route exact path='/' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><DashBoardCustomer /></Suspense></LayoutContainer>} />}
				{customer && <Route exact path='/painel' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><DashBoardCustomer /></Suspense></LayoutContainer>} />}

				{microBda && <Route exact path='/mudar-email' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><MudarEmail /></Suspense></LayoutContainer>} />}
				{microBda && <Route exact path='/site-map' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><SiteMap /></Suspense></LayoutContainer>} />}
				{microBda && <Route exact path='/add-originacao/:id?' component={() => <LayoutContainer menu={false}><Suspense fallback={<ThisLoading />}><AddOriginacaoNew /></Suspense></LayoutContainer>} />}
				{microBda && <Route exact path='/teses/' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><NewTeses /></Suspense></LayoutContainer>} />}
				{microBda && <Route exact path='/teaser/:id' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><Teaser /></Suspense></LayoutContainer>} />}
				{microBda && <Route exact path='/kit-comercial' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><KitComercial /></Suspense></LayoutContainer>} />}
				{microBda && <Route exact path='/tese/:id' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><Index_Tese /></Suspense></LayoutContainer>} />}
				{microBda && <Route exact path='/teasers' component={() => <LayoutContainer customLayout><Suspense fallback={<ThisLoading />}><Originacoes /></Suspense></LayoutContainer>} />}
				{microBda && <Route exact path='/teasers/:newOrigination' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><Originacoes /></Suspense></LayoutContainer>} />}
				{microBda && <Route exact path='/perfil' component={Index_PerfilBDA} />}
				{microBda && <Route exact path='/teaser-add-obrigado/:id?' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><AddOriginacaoObrigado /></Suspense></LayoutContainer>} />}
				{microBda && <Route exact path='/teasers/:newOrigination' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><Originacoes /></Suspense></LayoutContainer>} />}
				{microBda && <Route exact path='/' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><DashBoardAgent /></Suspense></LayoutContainer>} />}
				{microBda && <Route exact path='/painel' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><DashBoardAgent /></Suspense></LayoutContainer>} />}

				{officeAnalyst && <Route exact path='/mudar-email' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><MudarEmail /></Suspense></LayoutContainer>} />}
				{officeAnalyst && <Route exact path='/site-map' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><SiteMap /></Suspense></LayoutContainer>} />}
				{officeAnalyst && <Route exact path='/academy' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><Academy /></Suspense></LayoutContainer>} />}
				{officeAnalyst && <Route exact path='/teses/' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><NewTeses /></Suspense></LayoutContainer>} />}
				{officeAnalyst && <Route exact path='/tese/:id' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><Index_Tese /></Suspense></LayoutContainer>} />}
				{officeAnalyst && <Route exact path='/teaser/:id' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><Teaser /></Suspense></LayoutContainer>} />}
				{officeAnalyst && <Route exact path='/darwin-light' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><DarwinLight /></Suspense></LayoutContainer>} />}
				{officeAnalyst && <Route exact path='/profile-company/:id' component={() => <LayoutContainer customLayout><Suspense fallback={<ThisLoading />}><ProfileCompany /></Suspense></LayoutContainer>} />}
				{officeAnalyst && <Route exact path='/add-originacao/:id?' component={() => <LayoutContainer menu={false}><Suspense fallback={<ThisLoading />}><AddOriginacaoNew /></Suspense></LayoutContainer>} />}
				{officeAnalyst && <Route exact path='/teaser-add-obrigado/:id?' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><AddOriginacaoObrigado /></Suspense></LayoutContainer>} />}
				{officeAnalyst && <Route exact path='/active-customers' component={() => <LayoutContainer customLayout><Suspense fallback={<ThisLoading />}><ActiveCustomers /></Suspense></LayoutContainer>} />}
				{officeAnalyst && <Route exact path='/active-customer/:id/dashboard' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><ActiveCustomerDashboard /></Suspense></LayoutContainer>} />}
				{officeAnalyst && <Route exact path='/active-customer/:teaserId/communication/:matchId' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><ActiveCustomerCommunication /></Suspense></LayoutContainer>} />}
				{officeAnalyst && <Route exact path='/originations' component={() => <LayoutContainer customLayout><Suspense fallback={<ThisLoading />}><Originations /></Suspense></LayoutContainer>} />}
				{officeAnalyst && <Route exact path='/user-management/' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><UserManagement /></Suspense></LayoutContainer>} />}
				{officeAnalyst && <Route exact path='/painel' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><Originations /></Suspense></LayoutContainer>} />}
				{officeAnalyst && <Route exact path='/perfil/' component={() => <LayoutContainer customLayout><Suspense fallback={<ThisLoading />}><ProfileCorporativo /></Suspense></LayoutContainer>} />}
				{officeAnalyst && <Route exact path='/' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><Originations /></Suspense></LayoutContainer>} />}

				<Route exact path='/modelo-padrão' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><ModeloPadrão /></Suspense></LayoutContainer>} />
				<Route exact path='/estudo' component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><Estudo /></Suspense></LayoutContainer>} />
				<Route component={() => <LayoutContainer><Suspense fallback={<ThisLoading />}><Page404 /></Suspense></LayoutContainer>} />
			</Switch>
		</AnimatePresence>
	)
}
