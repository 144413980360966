import { GEN_STARK_FEE } from '../../genConst/GEN_STARK_FEE'
import { GEN_MARGIN_FEE } from '../../genConst/GEN_MARGIN_FEE'
import { genFee } from '../../genCount/genFee'
import { normalize } from '../../normalize'
import { TypeScoreProps } from '../../types/TypeScoreProps'
import { TypeScoreStark } from '../../types/TypeScoreStark'

export const scoreStarkCréditoProjectFinance = (props: TypeScoreProps) => {
	let score: TypeScoreStark = {
		approved: false,
		scoreColor: 'red',
		scoreNote: 0,
		scoreItens: [],
		metadata: {
			valuation: 0,
			transactionValue: 0,
			successFee: 0,
			valueTotalInsure: 0,
			typeOperation: 'projectFinance',
			type: 'Crédito',
			reperfilamento: false,
			isAdjustableValue: false,
			numberOfInstitutions: {
				total: 0,
				specific: 0,
				setorial: 0,
				generalist: 0,
			},
			insureCoverage: 0,
			governance: 'C',
			governanceColor: 'red',
			governanceCoverage: 0,
		}
	}

	/** valor da Transação de project finance */
	const thisValorDesejado = genFee(props)
	/** Obra financiada */
	const thisFinancing = props.teaser?.financing?.fin1?.[0] // multiplicador 1
	/** cronograma da obra financiada */
	const thisCronograma = +(thisFinancing?.timeline || 0) // multiplicador 1
	/** porcentagem da obra */
	const thisConstruction = +(thisFinancing?.construction || 0) // multiplicador 1
	/** recebiveis da obra */
	const thisRecebíveis = +(thisFinancing?.saldoCarteiraRecebiveis || 0) // multiplicador 1
	/** VGV */
	const thisVGV = +(thisFinancing?.vgv || 0) // multiplicador 1
	/** Caixa do empreendimento */
	const thisCaixa = +(thisFinancing?.valorAtualCaixa || 0) // multiplicador 1
	/** Quanto já vendido */
	const thisSales = +(thisFinancing?.sales || 0) // multiplicador 1
	/** soma dos valores das garantias do tipo Recebíveis Imobiliários */
	// todo: adicionar na conta das garantias imobiliárias, as garantias daquele término + as garantias imobiliárias e recebiveis imobiliárias
	const thisGarantiaImobiliária = +(props.teaser?.financing?.fin1?.[0]?.vgv || 0) + +(props.teaser.guarantees?.['Recebíveis Imobiliários']?.reduce((acc, curr) => acc + (+(curr?.value || 0)), 0) || 0)
	/** garantias imobiliárias dividido pelo cronograma */
	const thisCobertura = thisGarantiaImobiliária / thisCronograma

	if (
		((1.2 * thisCronograma * thisConstruction) / (1 - thisConstruction)) + (thisRecebíveis + thisCaixa)
		<
		(0.8 * (thisVGV * thisSales)) / (1 - thisSales))
		score.scoreItens.push({
			pontosMax: -10,
			pontos: -10,
			pontosCor: 'red',
			setor: 'Penalidade de vendas',
			item: 'Penalidade de vendas',
			itemDescrição: 'A obra apresenta indícios de vendas muito abaixo do valor de tabela e/ou envio de recursos para outras finalidades',
			devolutiva: 'A obra apresenta indícios de vendas muito abaixo do valor de tabela e/ou envio de recursos para outras finalidades',
			metaDados: '',
		})
	else {
		score.scoreItens.push({
			pontosMax: -10,
			pontos: 0,
			pontosCor: 'red',
			setor: 'Penalidade de vendas',
			item: 'Penalidade de vendas',
			itemDescrição: 'A obra apresenta indícios de vendas muito abaixo do valor de tabela e/ou envio de recursos para outras finalidades',
			devolutiva: '',
			metaDados: '',
		})
	}

	// todo: obra já possui registro de incorporação?

	// === fee acima do mínimo Stark ?
	// Caso o valor de fee seja maior igual a 800_000, adiciona 5 pontos (busca pelo cronograma de todas as obras)
	if (thisValorDesejado >= (1 * GEN_STARK_FEE(props)[0])) // 5 pontos
		score.scoreItens.push({
			pontosMax: 5,
			pontos: 5,
			pontosCor: 'green',
			setor: 'Taxa de sucesso',
			item: 'nome do item',
			itemDescrição: 'Taxa de sucesso Stark',
			devolutiva: '',
			metaDados: '',
		})
	else {
		score.scoreItens.push({
			pontosMax: 5,
			pontos: 0,
			pontosCor: 'green',
			setor: 'Taxa de sucesso',
			item: 'nome do item',
			itemDescrição: 'Taxa de sucesso Stark',
			devolutiva: '',
			metaDados: '',
		})
	}
	// Caso o valor de fee seja maior igual a 1_000_000, adiciona mais 1 ponto
	if (thisValorDesejado >= (2.5 * GEN_STARK_FEE(props)[0])) // 1 pontos
		score.scoreItens.push({
			pontosMax: 1,
			pontos: 1,
			pontosCor: 'green',
			setor: 'Taxa de sucesso',
			item: 'Bônus por sucesso',
			itemDescrição: 'Bônus por sucesso',
			devolutiva: '',
			metaDados: '',
		})
	else {
		score.scoreItens.push({
			pontosMax: 1,
			pontos: 0,
			pontosCor: 'green',
			setor: 'Taxa de sucesso',
			item: 'Bônus por sucesso',
			itemDescrição: 'Bônus por sucesso',
			devolutiva: '',
			metaDados: '',
		})
	}

	// === Penalidade de cronograma
	// Caso o cronograma seja inferior a 75% de 800_000, adiciona -5 pontos
	// todo: o que de fato é este item? Considerando vários cronogramas.
	// todo: esta regra está redundante
	// if ((thisCronograma) < (0.75 * GEN_STARK_FEE(props)[0])) // -5 pontos
	// 	score.scoreItens.push({
	// 		pontosMax: 0,
	// 		pontos: -5,
	// 		pontosCor: 'red',
	// 		setor: 'Penalidade de cronograma',
	// 		item: 'Penalidade de cronograma',
	// 		itemDescrição: 'Cronograma inferior do que o esperado',
	// 		devolutiva: 'Cronograma inferior do que o esperado',
	// 		metaDados: '',
	// 	})
	// Caso o valor das garantias imobiliárias seja inferior a 130% do cronograma, adiciona -5 pontos
	if ((thisGarantiaImobiliária) < (1.3 * thisCronograma)) // -5 pontos
		score.scoreItens.push({
			pontosMax: 0,
			pontos: -5,
			pontosCor: 'red',
			setor: 'Penalidade de cronograma',
			item: 'Penalidade de cronograma',
			itemDescrição: 'Garantias imobiliárias inferiores a 130% do cronograma',
			devolutiva: 'Garantias imobiliárias inferiores a 130% do cronograma',
			metaDados: '',
		})
	else {
		score.scoreItens.push({
			pontosMax: -5,
			pontos: 0,
			pontosCor: 'red',
			setor: 'Penalidade de cronograma',
			item: 'Penalidade de cronograma',
			itemDescrição: 'Garantias imobiliárias inferiores a 130% do cronograma',
			devolutiva: '',
			metaDados: '',
		})
	}


	// === Nota de vendas
	// Caso as vendas sejam inferiores a 20%, adiciona -2 pontos
	if ((thisFinancing?.sales || 0) < 20) // -2 pontos
		score.scoreItens.push({
			pontosMax: 2,
			pontos: -2,
			pontosCor: 'red',
			setor: 'Nota de vendas',
			item: 'Nota de vendas',
			itemDescrição: 'Vendas inferiores a 20%',
			devolutiva: 'Vendas inferiores a 20%',
			metaDados: '',
		})
	// Caso as vendas estejam entre 20% e 30%, adiciona 0.5 pontos
	else if (20 <= (thisFinancing?.sales || 0) && (thisFinancing?.sales || 0) < 30) // 0.5 pontos
		score.scoreItens.push({
			pontosMax: 2,
			pontos: 0.5,
			pontosCor: 'green',
			setor: 'Nota de vendas',
			item: 'Nota de vendas',
			itemDescrição: 'Vendas entre 20% e 30%',
			devolutiva: 'Vendas entre 20% e 30%',
			metaDados: '',
		})
	// Caso as vendas estejam entre 30% e 40%, adiciona 1 pontos
	else if (30 <= (thisFinancing?.sales || 0) && (thisFinancing?.sales || 0) < 40) // 1 pontos
		score.scoreItens.push({
			pontosMax: 2,
			pontos: 1,
			pontosCor: 'green',
			setor: 'Nota de vendas',
			item: 'Nota de vendas',
			itemDescrição: 'Vendas entre 30% e 40%',
			devolutiva: 'Vendas entre 30% e 40%',
			metaDados: '',
		})
	// Caso as vendas estejam entre 40% e 50%, adiciona 1.5 pontos
	else if (40 <= (thisFinancing?.sales || 0) && (thisFinancing?.sales || 0) < 50) // 1.5 pontos
		score.scoreItens.push({
			pontosMax: 2,
			pontos: 1.5,
			pontosCor: 'green',
			setor: 'Nota de vendas',
			item: 'Nota de vendas',
			itemDescrição: 'Vendas entre 40% e 50%',
			devolutiva: 'Vendas entre 40% e 50%',
			metaDados: '',
		})
	// Caso as vendas sejam superiores a 50%, adiciona 2 pontos
	else if (50 <= (thisFinancing?.sales || 0)) // 2 pontos
		score.scoreItens.push({
			pontosMax: 2,
			pontos: 2,
			pontosCor: 'green',
			setor: 'Nota de vendas',
			item: 'Nota de vendas',
			itemDescrição: 'Vendas acima de 50%',
			devolutiva: 'Vendas acima de 50%',
			metaDados: '',
		})
	else {
		score.scoreItens.push({
			pontosMax: 2,
			pontos: 0,
			pontosCor: 'green',
			setor: 'Nota de vendas',
			item: 'Nota de vendas',
			itemDescrição: 'Vendas acima de 50%',
			devolutiva: '',
			metaDados: '',
		})
	}


	// === Garantias
	// todo: só entrar imobiliárias e recebiveis todos os tipos da categoria imobiliário e todos os tipos da categoria recebíveis imobiliários
	// todo: a contagem da garantia de termino de obra, as garantias da categria imobiliário e recebíveis imobiliária

	if (thisValorDesejado < thisCobertura) {
		// cobertura entre 130% e 150% => 0.5 pontos
		if (1.3 <= thisCobertura && thisCobertura < 1.5) { // 0.5 pontos
			score.scoreItens.push({
				pontosMax: 2,
				pontos: 0.5,
				pontosCor: 'green',
				setor: 'Garantias',
				item: 'Garantias',
				itemDescrição: 'Cobertura entre 130% e 150%',
				devolutiva: 'Cobertura entre 130% e 150%',
				metaDados: '',
			})
		}
		// cobertura entre 150% e 180% => 1 pontos
		else if (1.5 <= thisCobertura && thisCobertura < 1.8) { // 1 pontos
			score.scoreItens.push({
				pontosMax: 2,
				pontos: 1,
				pontosCor: 'green',
				setor: 'Garantias',
				item: 'Garantias',
				itemDescrição: 'Cobertura entre 150% e 180%',
				devolutiva: 'Cobertura entre 150% e 180%',
				metaDados: '',
			})
		}
		// cobertura entre 180% e 200% => 1.5 pontos
		else if (1.8 <= thisCobertura && thisCobertura < 2) { // 1.5 pontos
			score.scoreItens.push({
				pontosMax: 2,
				pontos: 1.5,
				pontosCor: 'green',
				setor: 'Garantias',
				item: 'Garantias',
				itemDescrição: 'Cobertura entre 180% e 200%',
				devolutiva: 'Cobertura entre 180% e 200%',
				metaDados: '',
			})
		}
		// cobertura acima de 200% => 2 pontos
		else if (2 <= thisCobertura) { // 2 pontos
			score.scoreItens.push({
				pontosMax: 2,
				pontos: 2,
				pontosCor: 'green',
				setor: 'Garantias',
				item: 'Garantias',
				itemDescrição: 'Cobertura acima de 200%',
				devolutiva: 'Cobertura acima de 200%',
				metaDados: '',
			})
		}
		else { // 2 pontos
			score.scoreItens.push({
				pontosMax: 2,
				pontos: 0,
				pontosCor: 'green',
				setor: 'Garantias',
				item: 'Garantias',
				itemDescrição: 'Cobertura insuficiente',
				devolutiva: '',
				metaDados: '',
			})
		}
	}

	score = normalize(score)

	return score
}
