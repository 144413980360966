/* eslint-disable no-irregular-whitespace */
import { clone } from '../../Utils/clone'
import { TypeCalendar } from '../types/calendar'

/**
 * A função `modelCalendar` cria um novo objeto `TypeCalendar` com padrão
 *valores caso não sejam fornecidos.
 * @param obj - O parâmetro `obj` na função `modelCalendar` é parcial
 * objeto do tipo `TypeCalendar`. É usado para criar um novo objeto `TypeCalendar`
 * com valores padrão se as propriedades não forem fornecidas no parâmetro `obj`.
 * @returns A função `modelCalendar` está retornando um objeto do tipo
 * `TypeCalendar`. O objeto retornado contém propriedades `_id`, `title`, `place`,
 * `dateStart`, `dateEnd`, `url`, `createdAt` e `updatedAt`. Se essas propriedades
 * não estão presentes no parâmetro `obj`, valores padrão são usados ​(string vazia
 * para `_id`, título`, `lugar`, `url`,
 */
//CRIADO POR IA

export const modelCalendar = (obj: Partial<TypeCalendar> = {}): TypeCalendar => {

	const cloneObj = clone(obj)

	return ({
		_id: cloneObj._id || '',
		title: cloneObj.title || '',
		place: cloneObj.place || '',
		dateStart: cloneObj.dateStart || 0,
		dateEnd: cloneObj.dateEnd || 0,
		url: cloneObj.url || '',
		createdAt: cloneObj.createdAt || 0,
		updatedAt: cloneObj.updatedAt || 0,
	})

}
