import { getEbWithSeptemberRule } from '../../get/getEbWithSeptemberRule'
import { getRealInsure } from '../../get/getRealInsure'
import { getTotalDebit } from '../../get/getTotalDebit'
import { getTotalInsure } from '../../get/getTotalInsure'
import { MULTIPLE_EB1_FOR_DEBT } from '../genConst/GEN_MULTIPLE_EB1_FOR_DEBT'
import { GEN_STARK_FEE_BIG } from '../genConst/GEN_STARK_FEE_BIG'
import { genTransactionValue } from '../genCount/genTransactionValue'
import { TypeScoreProps } from '../types/TypeScoreProps'
import { TypeScoreStark } from '../types/TypeScoreStark'

export const genScoreDebitEbitda = (props: TypeScoreProps) => {
	// if (
	// 	debt + 20_000_000 > MULTIPLE_EB1_FOR_DEBT * eb1 &&
	// 	valueInsureReal < transactionValue &&
	// 	(valueTotalInsure * 0.8 < BIG_TRANSACTION || valueTotalInsure * 0.8 < debt)
	// )
	// 	subSections.push(SCORE_SUBSECTIONS.s303._id)
	// else
	// 	subSections.push(SCORE_SUBSECTIONS.s231._id)

	const realInsure = getRealInsure(props.teaser)
	const totalInsure = getTotalInsure(props.teaser)
	const debit = getTotalDebit(props.teaser)

	const res: TypeScoreStark['scoreItens'] = []

	const devolutiva = 'Construa, junto ao time da empresa, formas de reduzir o seu endividamento e/ou elevar a sua margem EBITDA.'

	if (
		debit + 10_000_000 > MULTIPLE_EB1_FOR_DEBT() * getEbWithSeptemberRule(props.teaser) &&
		realInsure < genTransactionValue(props) &&
		(totalInsure * 0.8 < GEN_STARK_FEE_BIG(props.operation) || totalInsure * 0.8 < debit)
	) {
		res.push({ // adiciona -2
			'pontosMax': 2,
			'pontos': -2,
			'pontosCor': 'red',
			'setor': 'Penalidade de dívida',
			'item': 'Penalidade de dívida',
			'itemDescrição': 'Empresa com alto endividamento e/ou baixo ebitda para suportar esta transação',
			'metaDados': '',
			'devolutiva': devolutiva,
		})
	}
	else {
		res.push({ // adiciona 0
			'pontosMax': 2,
			'pontos': 0,
			'pontosCor': 'red',
			'setor': 'Penalidade de dívida',
			'item': 'Penalidade de dívida',
			'itemDescrição': 'Ebitda e endividamento suficiente para suportar esta transação',
			'metaDados': '',
			'devolutiva': '',
		})
	}

	return res
}
