import { TypeViewOriginacoes } from './TypeViewOriginacoes'

export const modelViewOriginacoes = (obj: Partial<TypeViewOriginacoes>): TypeViewOriginacoes => ({
	onClick: {
		teaser_id: obj.onClick?.teaser_id || '',
	},
	element: {
		tab: obj.element?.tab || 0,
		onLine: obj.element?.onLine ?? true,

		urlLogo: obj.element?.urlLogo || '',
		fantasyName: obj.element?.fantasyName || '',
		codinome: obj.element?.codinome || '',
		nome: obj.element?.nome || '',
		tags: obj.element?.tags || [],
		dataPublicacao: obj.element?.dataPublicacao || 0,
		autor: {
			autor_uid: obj.element?.autor?.autor_uid || '',
			nome: obj.element?.autor?.nome || '',
			img: obj.element?.autor?.img || '',
			complement: obj.element?.autor?.complement || '',
			bulletColor: obj.element?.autor?.bulletColor || '',
			type: obj.element?.autor?.type || '',
		},
		statusLabel: obj.element?.statusLabel || '',
		dateStatus: obj.element?.dateStatus || '',
		office: {
			office_id: obj.element?.office?.office_id || '',
			nome: obj.element?.office?.nome || '',
			ulrimg: obj.element?.office?.ulrimg || '',

		},
		exclusividade: {
			defeated: obj.element?.exclusividade?.defeated || false,
			resp: obj.element?.exclusividade?.resp || '',
		},
		isDataAjustes: obj.element?.isDataAjustes || false,
		dataAjustesLast: obj.element?.dataAjustesLast || '',
		validation: {
			dateMeet: obj.element?.validation?.dateMeet || null,
			promotedBy: obj.element?.validation?.promotedBy || '',
			isDone: obj.element?.validation?.isDone || false,
			validationDaysStringFormatted: obj.element?.validation?.validationDaysStringFormatted || '',
		},
		contrato: {
			tipoContrato: obj.element?.contrato?.tipoContrato || '',
			dataContrato: obj.element?.contrato?.dataContrato || 0,
		}
	},
})
