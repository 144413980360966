import { genScoreDebitEbitda } from '../../genScore/genScoreDebitEbitda'
import { genScoreFee } from '../../genScore/genScoreFee'
import { genScoreFeeBigValue } from '../../genScore/genScoreFeeBigValue'
import { genScoreValueInsure } from '../../genScore/genScoreValueInsure'
import { TypeScoreProps } from '../../types/TypeScoreProps'
import { TypeScoreStark } from '../../types/TypeScoreStark'
import { normalize } from '../../normalize'
import { genScoreGov } from '../../genScore/genScoreGov'

export const scoreStarkCréditoCorporateFinance = (props: TypeScoreProps) => {
	let score: TypeScoreStark = {
		approved: false,
		scoreColor: 'red',
		scoreNote: 0,
		scoreItens: [],
		metadata: {
			valuation: 0,
			transactionValue: 0,
			successFee: 0,
			valueTotalInsure: 0,
			typeOperation: 'corporateFinance',
			type: 'Crédito',
			reperfilamento: false,
			isAdjustableValue: false,
			numberOfInstitutions: {
				total: 0,
				specific: 0,
				setorial: 0,
				generalist: 0,
			},
			insureCoverage: 0,
			governance: 'C',
			governanceColor: 'red',
			governanceCoverage: 0,
		}
	}

	// Governança
	// apenas adiciona devolutivas caso a empresa tipo C ou tipo B
	score.scoreItens.push(...genScoreGov(props))

	// todo: será que precisa ser uma faixa com vários valores...?
	// fee acima do mínimo Stark ?
	// considerando 0.04 de margem para crédito
	// Caso o valor de fee seja maior igual a 800_000, adiciona 5 pontos
	// Caso seja menor que 800_000, adiciona 0 pontos
	// obs: o valor da transação é o valor desejado do teaser
	score.scoreItens.push(...genScoreFee(props))

	// fee é grande o suficiente para cobrir o risco ?
	// Caso o valor do fee seja superior a 2_000_000, adiciona 1 ponto
	// Caso seja menor que 2_000_000, adiciona 0 pontos
	// obs: Este valor é derivado do valor de transação 50_000_000 * 0.04
	score.scoreItens.push(...genScoreFeeBigValue(props))

	// Garantias superiores ao valor da transação
	// Gov A
	//    de 130% a 160% de cobertura => 2 pontos
	//    acima de 160% de cobertura => 3 pontos
	// Gov B
	//    de 150% a 160% de cobertura => 1 pontos
	//    de 160% a 200% de cobertura => 2 pontos
	//    acima de 200% de cobertura => 3 pontos
	// Gov C
	//    de 180% a 200% de cobertura => 1 pontos
	//    acima de 200% de cobertura => 2 pontos
	score.scoreItens.push(...genScoreValueInsure(props))

	// Dívida e ebitda suficiente para suportar a o
	// As tres linhas abaixo devem retornar verdadeiro para adicionar -2 pontos
	//    divida + 10_000_000 > 5 * ebitda &&
	//    valor de garantias reais < valor da transação &&
	//    (valor total de garantias * 0.8 < 50_000_000 || valor total de garantias * 0.8 < divida)
	score.scoreItens.push(...genScoreDebitEbitda(props))

	// normalização dos pontos
	score = normalize(score)

	return score
}
