import React from 'react'
import { LogoStark } from 'Components/Layout/LogoStark'
import { TypeUser } from 'Functions/User/types/TypeUser'
import { useHistory } from 'react-router-dom'
import { TMenu } from './menu'
import { generateUniqueId } from 'Functions/Utils/generateUniqueId'
import { useContextAuth } from 'App/ContextAuth'
import { encrypt } from 'Functions/Utils/encript'

export const menuAdm = (profile: TypeUser, menu: string, setMenu: React.Dispatch<React.SetStateAction<string>>): TMenu => {

	const history = useHistory()

	const { globalAuth } = useContextAuth()
	const codeProfile = encrypt(globalAuth.email)

	return ([
		{
			Component: (<>
				<div className='tw-hidden md:tw-block'>
					<LogoStark />
				</div>
				<div className='tw-block md:tw-hidden tw-h-[10px]'></div>
			</>),
			key: generateUniqueId(),
			type: 'component',
		},
		{
			title: 'Dash',
			key: generateUniqueId(),
			type: 'link',
			icon: <span className='material-icons-outlined tw-text-xl'> home </span>,
			iconActive: <i className='material-icons tw-text-xl'>home</i>,
			checkActive: history.location.pathname === '/',
			to: '/'
		},
		{
			title: 'Usuários',
			key: generateUniqueId(),
			type: 'link',
			icon: <i className='material-icons-outlined tw-text-xl'> people </i>,
			iconActive: <i className='material-icons tw-text-xl'>people</i>,
			checkActive: history.location.pathname.startsWith('/users/'),
			to: '/users/'
		},
		{
			title: 'Adicionar Conta',
			key: generateUniqueId(),
			type: 'link',
			icon: <i className='material-icons-outlined tw-text-xl'> person_add_alt </i>,
			iconActive: <i className='material-icons-outlined tw-text-xl'> person_add_alt </i>,
			checkActive: history.location.pathname.startsWith('/create-bda/'),
			to: '/create-bda/'
		},
		{
			title: 'Academy',
			key: generateUniqueId(),
			type: 'link',
			icon: <i className='material-icons-outlined tw-text-xl'> school </i>,
			iconActive: <i className='material-icons tw-text-xl'>school</i>,
			//checkActive: history.location.pathname.startsWith('/academy/'),
			onClick: () => window.open(`https://stark.weduka.com.br/Authentication/Consume/?id=${codeProfile}`, '_blank')
		},

		{
			title: 'Dev',
			key: generateUniqueId(),
			type: 'linkGroup',
			icon: <i className='material-icons-outlined tw-text-xl'>science</i>,
			iconActive: <i className='material-icons tw-text-xl'>science</i>,
			onClick: () => setMenu(menu === 'Dev' ? '' : 'Dev'),
			subLinks: [
				{
					title: 'DataBase',
					key: generateUniqueId(),
					type: 'link',
					// icon: <i className='material-icons-outlined tw-text-xl'> arrow_right </i>,
					checkActive: history.location.pathname.startsWith('/database/'),
					to: '/database/'
				},
				{
					title: 'User Control',
					key: generateUniqueId(),
					type: 'link',
					// icon: <i className='material-icons-outlined tw-text-xl'> arrow_right </i>,
					checkActive: history.location.pathname.startsWith('/user-control/'),
					to: '/user-control/'
				},
				{
					title: 'Alexandria',
					key: generateUniqueId(),
					type: 'link',
					// icon: <i className='material-icons-outlined tw-text-xl'> arrow_right </i>,
					checkActive: history.location.pathname.startsWith('/alexandria/'),
					to: '/alexandria/'
				},
				{
					title: 'Snaps',
					key: generateUniqueId(),
					type: 'link',
					// icon: <i className='material-icons-outlined tw-text-xl'> arrow_right </i>,
					checkActive: history.location.pathname.startsWith('/snaps/'),
					to: '/snaps/'
				},
			]
		},

		{
			Component: (<>
				<div className='tw-ml-4 tw-mt-6 tw-px-2 tw-font-bold tw-text-center tw-flex tw-items-center tw-gap-4'>
					<div className='tw-font-bold tw-text-neutral-300'>Operações</div>
				</div>
			</>),
			key: generateUniqueId(),
			type: 'component',
		},

		{
			title: 'Clientes',
			key: generateUniqueId(),
			type: 'link',
			icon: <i className='material-icons-outlined tw-text-xl'> business </i>,
			iconActive: <i className='material-icons tw-text-xl'>business</i>,
			checkActive: history.location.pathname.startsWith('/active-customers/'),
			to: '/active-customers/'
		},
		// {
		// 	title: 'Usuários',
		// 	key: generateUniqueId(),
		// 	type: 'link',
		// 	icon: <i className='material-icons-outlined tw-text-xl'> people </i>,
		// iconActive: <i className='material-icons tw-text-xl'>home</i>,
		// 	checkActive: history.location.pathname.startsWith('/user-management/'),
		// 	onClick: () => history.push('/user-management/')
		// },
		{
			title: 'Ferramentas',
			key: generateUniqueId(),
			type: 'linkGroup',
			icon: <i className='material-icons-outlined tw-text-xl'>construction</i>,
			iconActive: <i className='material-icons tw-text-xl'>construction</i>,
			onClick: () => setMenu(menu === 'Ferramentas' ? '' : 'Ferramentas'),
			subLinks: [
				{
					title: 'Insightz',
					key: generateUniqueId(),
					type: 'link',
					// icon: <i className='material-icons-outlined tw-text-xl'> arrow_right </i>,
					checkActive: history.location.pathname.startsWith('/insightz/'),
					to: '/insightz/'
				},
				// {
				// 	title: 'Darwin Light',
				// 	key: generateUniqueId(),
				// 	type: 'link',
				// 	// icon: <i className='material-icons-outlined tw-text-xl'> arrow_right </i>,
				// 	checkActive: history.location.pathname.startsWith('/darwin-light/'),
				// 	to: '/darwin-light/'
				// },
				{
					title: 'Darwin Light',
					key: generateUniqueId(),
					type: 'link',
					// icon: <i className='material-icons-outlined tw-text-xl'> arrow_right </i>,
					checkActive: history.location.pathname.startsWith('/darwin-light/'),
					to: '/darwin-two/'
				},
				// {
				// 	title: 'Darwin',
				// 	key: generateUniqueId(),
				// 	type: 'link',
				// 	// icon: <i className='material-icons-outlined tw-text-xl'> arrow_right </i>,
				// 	checkActive: history.location.pathname.startsWith('/darwin/'),
				// 	to: '/darwin/'
				// },
				{
					title: 'Sinatra IA',
					key: generateUniqueId(),
					type: 'link',
					// icon: <i className='material-icons-outlined tw-text-xl'> arrow_right </i>,
					checkActive: history.location.pathname.startsWith('/sinatra/'),
					to: '/sinatra/'
				}
			]
		},

		{
			Component: (<>
				<div className='tw-ml-4 tw-mt-6 tw-px-2 tw-font-bold tw-text-center tw-flex tw-items-center tw-gap-4'>
					<div className='tw-font-bold tw-text-neutral-300'>RI</div>
				</div>
			</>),
			key: generateUniqueId(),
			type: 'component',
		},

		{
			title: 'Combinações',
			key: generateUniqueId(),
			type: 'link',
			icon: <i className='material-icons-outlined tw-text-xl'> join_inner </i>,
			iconActive: <i className='material-icons tw-text-xl'>join_inner</i>,
			checkActive: history.location.pathname.startsWith('/matches/pre-matches/'),
			to: '/matches/pre-matches/'
		},
		// {
		// 	title: 'Investidores',
		// 	key: generateUniqueId(),
		// 	type: 'linkGroup',
		// 	icon: <i className='material-icons-outlined tw-text-xl'> account_balance </i>,
		// 	iconActive: <i className='material-icons tw-text-xl'>account_balance</i>,
		// 	onClick: () => setMenu(menu === 'Investidores' ? '' : 'Investidores'),
		// 	subLinks: [
		// {
		// 	title: 'Novo Instituições',
		// 	key: generateUniqueId(),
		// 	type: 'link',
		// 	// icon: <i className='material-icons-outlined tw-text-xl'> arrow_right </i>,
		// 	checkActive: history.location.pathname.startsWith('/new-instituicoes/'),
		// 	to: '/new-instituicoes/'
		// },
		{
			title: 'Instituições',
			key: generateUniqueId(),
			type: 'link',
			icon: <i className='material-icons-outlined tw-text-xl'> account_balance </i>,
			iconActive: <i className='material-icons-outlined tw-text-xl'> account_balance </i>,
			checkActive: history.location.pathname.startsWith('/instituicoes/'),
			to: '/instituicoes/'
		},
		{
			title: 'Teses',
			key: generateUniqueId(),
			type: 'link',
			icon: <i className='material-icons-outlined tw-text-xl'> description </i>,
			iconActive: <i className='material-icons-outlined tw-text-xl'> description </i>,
			checkActive: history.location.pathname.startsWith('/teses/'),
			to: '/teses/'
		},
		{
			title: 'Teses de crédito',
			key: generateUniqueId(),
			type: 'link',
			icon: <i className='material-icons-outlined tw-text-xl'> description </i>,
			iconActive: <i className='material-icons-outlined tw-text-xl'> description </i>,
			checkActive: history.location.pathname.startsWith('/teses-credit/'),
			to: '/teses-credit/'
		},
		// 	]
		// },

		{
			Component: (<>
				<div className='tw-ml-4 tw-mt-6 tw-px-2 tw-font-bold tw-text-center tw-flex tw-items-center tw-gap-4'>
					<div className='tw-font-bold tw-text-neutral-300'>Originações</div>
				</div>
			</>),
			key: generateUniqueId(),
			type: 'component',
		},


		// {
		// 	title: 'Agentes',
		// 	key: generateUniqueId(),
		// 	type: 'linkGroup',
		// 	icon: <i className='material-icons-outlined tw-text-xl'> groups </i>,
		// 	iconActive: <i className='material-icons tw-text-xl'>groups</i>,
		// 	onClick: () => setMenu(menu === 'Agentes' ? '' : 'Agentes'),
		// 	subLinks: [
		{
			title: 'Gestão',
			key: generateUniqueId(),
			type: 'link',
			// icon: <i className='material-icons-outlined tw-text-xl'> wallet </i>,
			icon: <i className='material-icons-outlined tw-text-xl'> manage_accounts </i>,
			iconActive: <i className='material-icons tw-text-xl'>manage_accounts</i>,
			checkActive: history.location.pathname.startsWith('/canais/'),
			to: '/canais/dashboard/'
		},
		{
			title: 'Originadores',
			key: generateUniqueId(),
			type: 'link',
			icon: <i className='material-icons-outlined tw-text-xl'> people </i>,
			iconActive: <i className='material-icons-outlined tw-text-xl'> people </i>,
			checkActive: history.location.pathname.startsWith('/originators/'),
			to: '/originators/'
		},
		{
			title: 'Funil',
			key: generateUniqueId(),
			type: 'link',
			icon: <i className='material-icons-outlined tw-text-xl'> filter_alt </i>,
			iconActive: <i className='material-icons tw-text-xl'>filter_alt</i>,
			checkActive: history.location.pathname.startsWith('/originations/'),
			to: '/originations/'
		},
		// {
		// 	title: 'Carteira',
		// 	key: generateUniqueId(),
		// 	type: 'link',
		// 	// icon: <i className='material-icons-outlined tw-text-xl'> wallet </i>,
		// 	icon: <i className='material-icons-outlined tw-text-xl'> account_balance_wallet </i>,
		// 	iconActive: <i className='material-icons tw-text-xl'>account_balance_wallet</i>,
		// 	checkActive: history.location.pathname.startsWith('/teasers/'),
		// 	to: '/teasers/'
		// },
		// {
		// 	title: 'Kit Stark',
		// 	key: generateUniqueId(),
		// 	type: 'link',
		// 	icon: <i className='material-icons-outlined tw-text-xl'> folder_copy </i>,
		// 	iconActive: <i className='material-icons-outlined tw-text-xl'> folder_copy </i>,
		// 	checkActive: history.location.pathname.startsWith('/kit-comercial/'),
		// 	to: '/kit-comercial/'
		// },
		// 	]
		// },



	])
}
