import { TypeTeaser } from '../../Teaser/types/teaser'
import { getPercentageCompleted } from './getPercentageCompleted'

export const getStatus = (teaser: TypeTeaser): {
	status: string
	type: string
} => {
	const percentage: number = getPercentageCompleted(teaser)
	const status = percentage !== 100 ? 'Incompleto' : 'Completo'
	const type = percentage === 100 ? 'primary' : (percentage >= 51 ? 'success' : 'danger')

	return {
		status,
		type
	}
}
